import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Grafik from "../components/grafik"

const GrafikPage = () => {
  return (
    <Layout pageInfo={{ pageName: "grafik" }}>
      <SEO title="Grafik"/>
      <Grafik/>
    </Layout>
  )
}

export default GrafikPage
